<template>
  <div class="user-layout withTopBar">
    <van-nav-bar title="预存信息" left-text="返回" left-arrow safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
    <div class="loading" v-show="loading">
      <van-loading type="spinner" color="#1989fa" />
    </div>
    <van-empty v-show="!loading && userData.length==0" description="暂无数据" />
    <div class="item" v-for="(item,index) in userData" :key="'user'+index" @click="selectAddress(index)">
      <div class="holder">
        <div class="flex_box align_center font15">
          <span>{{item.name}}</span>
          <span class="pdl15">{{item.mobile}}</span>
        </div>
        <div class="color_gray font14 pdt8">{{item.address_str}}</div>
        <div class="color_gray font14 pdt5">{{item.isbox=="0" ? '不需要奶箱' : '需要奶箱'}}</div>
        <div class="text-right pdb10">
          <span class="user_btn edit_btn mr10" @click="editUser(index)">修改</span>
          <span class="user_btn buy_btn" v-if="type=='normal'" @click="goOrder">下单</span>
        </div>
      </div>
      <div class="blank"></div>
    </div>
    <div class="add_btn_box">
      <div class="add_btn flex_box align_center justify_center" @click="addAddr">
        <div class="iconfont icon-jia mr5 font14"></div>
        <span>新建</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"user-list",
  data(){
    return{
      user:{},
      type:"normal",
      preName:"",
      loading:false,
      userData:[]
    }
  },
  created(){
    let user = localStorage.getItem("cxyUserinfo")
    user ? this.user = JSON.parse(user) : ''
    let query = this.$route.query
    if(query.type){
      this.type = query.type
      this.preName = query.preName
    }
    this.index()
  },
  methods:{
    index(){
      const _this = this
      _this.loading = true
      _this.$api.common.getUserList({}).then(res => {
        _this.loading = false
        if(res.code == 0 && res.data){
          _this.userData = res.data
        }
      }).catch(() => {
        _this.loading = false;
      })
    },
    selectAddress(index){
      if(this.type =='select'){
        this.$store.commit('setShifUser', this.userData[index])
        this.onClickLeft()
      }
    },
    editUser(index){
      this.$store.commit('changeAddress', this.userData[index])
      this.$router.push({
        name: "user-input",
        query:{
          isScan:false,
        }
      })
    },
    addAddr(){
      this.$router.push({
        name: "user-input",
        query:{
          mode:"add",
          isScan:false,
          sales_id:this.user.sales_id,
          type:this.type,
          preName:this.preName
        }
      })
    },
    goOrder(){
      this.$router.push({
        name: "product-list"
      })
    },
    onClickLeft(){
      this.$router.push({
        name: this.type =='normal' ? "home" : this.preName
      })
    }
  },
  computed:{}
}
</script>

<style lang="scss">
@import "./user-list.scss";
</style>